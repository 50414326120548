$templateColour: blue;

@import 'core';

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none; /** IE */
  scrollbar-width: none; /* Firefox */
}
