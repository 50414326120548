/**
 * Availability
 */

#booknow .accordion-button {
  background: #fff;
  color: #333;
}

#booknow .accordion-button:focus {
  box-shadow: none;
}

#booknow tr,
#booknow td {
  vertical-align: middle !important;
}

#booknow tr {
  background: #fff;
  color: rgb(95, 95, 95);
}

#booknow tr.group-spaces-0 {
  background: rgb(229, 229, 229);
  color: rgb(120, 120, 120);
}

/**
 * Trip Dates
 */

#booknow .trip-dates-table-outer {
  padding: 0;
}

#booknow .table td.trip-date {
  display: table-cell;
  padding: 17px 0px 11px 4px;
  min-width: 150px;
  text-transform: uppercase;
}

#booknow .trip-date .clearfix {
  display: inline-block;
  margin-left: 4px;
}

#booknow .trip-date .start-date,
#booknow .trip-date .end-date {
  float: left;
  display: block;
}

#booknow .trip-date .day,
#booknow .trip-date .time {
  display: block;
  font-size: 9px;
  text-align: center;
}

#booknow .trip-date .date {
  display: block;
  /* font-size: 14px; */
  font-weight: 700;
  text-align: center;
}

#booknow .trip-date .date-arrow {
  font-size: 16px;
  height: 40px;
  padding: 12px 0 0;
  margin: -1px 3px 0 4px;
  display: block;
  float: left;
}

/**
 * Trip Prices
 */

.trip-price {
  text-transform: uppercase;
  padding: 0px 4px 0 10px !important;
}

.trip-price .price-formatted,
.trip-price .price-deposit,
.trip-price .price-deposit .price-formatted,
.trip-price .price-based-on,
.trip-price .price-based-on .price-formatted {
  text-align: left;
  /* font-size: 14px; */
  font-weight: 700;
  padding: 0;
  margin: 0;
}

.trip-price .price-deposit,
.trip-price .price-deposit .price-formatted {
  font-size: 12px;
}

.trip-price .price-deposit .price-formatted,
.trip-price .price-based-on .price-formatted {
  display: inline;
}

.trip-price .price-based-on,
.trip-price .price-based-on .price-formatted {
  font-size: 10px;
  font-weight: 500;
  color: rgb(119, 119, 119);
}

/**
 * Trip Quantity
 */

.trip-quantity {
  text-align: right;
  padding: 12px 8px 12px 0 !important;
}

.trip-quantity p {
  margin-bottom: 0;
}

.group-spaces-0 {
  background-color: #f0f1f1;
}

.trip-quantity .remaining-spaces {
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}

/**
 * Zoom for bigger screens
 */

@media only screen and (min-width: 400px) {
  .trip-date-inner {
    margin-left: 10px;
  }

  .trip-quantity-inner {
    margin-right: 8px;
  }
}

@media only screen and (min-width: 600px) {
  .trip-date .date-arrow {
    margin: 0 15px;
  }
}

/**
 * Bottom navbar
 */

#book-now-navbar {
  min-height: 75px;
  padding: 0 16px;
}

#book-now-navbar .navbar-stars {
  margin: -9px 0 -5px;
}

.show-selection:hover {
  /* border: solid 5px red; */
  box-shadow: 0px 0px 3px 3px #4e555b;
  filter: brightness(120%);
}
.is-selected {
  box-shadow: 0px 0px 6px 6px #218838;
}

.show-selection:hover {
  /* border: solid 5px red; */
  box-shadow: 0px 0px 3px 3px #4e555b;
  filter: brightness(120%);
}
.is-selected {
  box-shadow: 0px 0px 6px 6px #218838;
}

/* react-bootstrap-typeahead styling */
.rbt-close {
  border: none;
  background: none;
}

/**
 * Progress bar
 */

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #4e66f8;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
}

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow:
    0 0 10px #4e66f8,
    0 0 5px #4e66f8;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: #4e66f8;
  border-left-color: #4e66f8;
  border-radius: 50%;
  -webkit-animation: nprogresss-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}
@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
